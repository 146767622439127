import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './components/general/Home';
import WebPortal from './components/general/WebPortal';
import OnboardingForm from './components/forms/onboarding/OnboardingForm';
import PrevMeetingRoom from './components/meeting/PrevMeetingRoom';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import './css/App.css';
import FinalFeedbackForm from './components/forms/feedback/FinalFeedbackForm';
import FinalFeedbackFormMentor from './components/forms/feedback/FinalFeedbackFormMentor';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import OnBoardingAllForm from './components/forms/onboarding/all/OnBoardingAllForm';
import NewMenteeSurvey from './components/forms/onboarding/newMenteeSurvey/NewMenteeSurvey';
import NewCoacheeSurvey from './components/forms/onboarding/newCoacheeSurvey/NewCoacheeSurvey';
import NewCoachSurvey from './components/forms/onboarding/newCoachSurvey/NewCoachSurvey';
import NewMentorSurvey from './components/forms/onboarding/newMentorSurvey/NewMentorSurvey';
import FinalSessionCoachingSurvey from './pages/Tasks/finalAssessmentSurvey/FinalSessionCoachingSurvey';
import MidCohortCoachingSurvey from './pages/Tasks/midCohortAssessmentSurvey/MidCohortCoachingSurvey';
import Footer from './components/general/Footer';
import Logo from './components/general/Logo';
import PilotGroupCoachingAssessment from './pages/Tasks/pilotGroupCoachingAssessment/PilotGroupCoachingAssessment';
import OneOnOneFinalAssessment from './pages/Tasks/oneOnOneFinalSurvey/OneOnOneFinalAssessment';

function App() {
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return (
      <div style={{ marginLeft: '50%' }}>
        <CircularProgress
          color="secondary"
          style={{
            verticalAlign: 'middle'
          }}
        />
        <Typography style={{ display: 'inline-block', paddingLeft: '1rem', verticalAlign: 'sub' }}>Loading</Typography>
      </div>
    );
  }

  if (!isAuthenticated) {
    // return  <LoginButton />;
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route exact path={'/'} component={Home} /> */}
          <Route exact path={'/meetings/:meetingId'} component={PrevMeetingRoom} />
          <Route exact path={'/:clientId/mentors'} component={OnboardingForm} />
          <Route exact path={'/:clientId/mentees'} component={OnboardingForm} />
          <Route exact path={'/:code/all'} component={OnBoardingAllForm} />
          <Route exact path={'/:code/:userId/:surveyId/mentee'} component={NewMenteeSurvey} />
          <Route exact path={'/:code/:userId/:surveyId/mentors'} component={NewMentorSurvey} />
          <Route exact path={'/:code/:userId/:surveyId/coachee'} component={NewCoacheeSurvey} />
          <Route exact path={'/:code/:userId/:surveyId/coach'} component={NewCoachSurvey} />
          <Route exact path={'/:clientId/mentees/feedback/:menteeObject'} component={FinalFeedbackForm} />
          <Route exact path={'/:clientId/mentors/feedback/:mentorObject'} component={FinalFeedbackFormMentor} />
          <Route component={Home} />
        </Switch>
          <ToastContainer />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Logo />
        <Route exact path={'/meetings/:meetingId'} component={PrevMeetingRoom} />
        <Route exact path={'/:clientId/mentors'} component={OnboardingForm} />
        <Route exact path={'/:clientId/mentees'} component={OnboardingForm} />
        <Route exact path={'/:code/all'} component={OnBoardingAllForm} />
        <Route exact path={'/:code/:userId/:surveyId/mentee'} component={NewMenteeSurvey} />
        <Route exact path={'/:code/:userId/:surveyId/mentor'} component={NewMentorSurvey} />
        <Route exact path={'/:code/:userId/:surveyId/coachee'} component={NewCoacheeSurvey} />
        <Route exact path={'/:code/:userId/:surveyId/coach'} component={NewCoachSurvey} />
        <Route exact path={'/:clientId/mentees/feedback/:menteeObject'} component={FinalFeedbackForm} />
        <Route exact path={'/:clientId/mentors/feedback/:mentorObject'} component={FinalFeedbackFormMentor} />
        <Route exact path={'/final-assessment/:userId/:taskId/:groupId'} component={FinalSessionCoachingSurvey} />
        <Route exact path={'/pilot-assessment/:userId/:taskId/:groupId'} component={PilotGroupCoachingAssessment} />
        <Route exact path={'/mid-cohort-assessment/:userId/:taskId/:groupId'} component={MidCohortCoachingSurvey} />
        <Route exact path={'/one-on-one-final/:userId/:taskId/:groupId'} component={OneOnOneFinalAssessment} />
        <WebPortal />
        <ToastContainer />
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;