import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { fetchLearningTools, getLearningTools } from '../../redux/actions/content';
import { useDispatch, useSelector } from 'react-redux';
import {Colors, ResourcePlacement, ResourceStatus} from '../../enums/enums';

const useStyles = makeStyles({
  root: {
    width: '330px',
    height: '270px',
    marginLeft: '25px',
    marginBottom: '30px',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)', cursor: 'pointer' }
  }
});

export default function ToolsCarousel(userObject) {
  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1900 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1900, min: 1500 },
      items: 4
    },
    smallDesktop: {
      breakpoint: { max: 1500, min: 1150 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1150, min: 700 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1
    }
  };
  const userClientId = userObject?.user?.clientId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const learningTools = useSelector(getLearningTools);

  useEffect(() => {
    dispatch(fetchLearningTools());
  }, []);

  const activeLearningTools = learningTools?.filter((lt) => lt.status === ResourceStatus.Active && lt.placement === ResourcePlacement?.ResourcesPage);
  const learningToolsWithRestrictions = activeLearningTools?.filter((lt) =>
    lt.clientIds ? lt?.clientIds?.includes(userClientId) : true
  );

  return (
    <Box sx={{ mr: '120px', mt: '30px' }}>
      {!learningToolsWithRestrictions?.length && (
        <Typography sx={{ mb: '80px', mt: '60px', ml: 3, color: Colors.TextElevated, fontSize: '1.2rem' }}>
          New Learning Tools will be uploaded soon. {userObject?.name}
        </Typography>
      )}
      <Carousel containerClass="container" responsive={responsive} draggable={false}>
        {learningToolsWithRestrictions?.map((item) => (
          <a target="blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }} href={item?.azureUrl}>
            <Card key={item?.id} className={classes.root}>
              <CardMedia sx={{ height: '180px' }} component="img" image={item?.thumbnailUrl} />
              <CardContent>
                <Typography textAlign="center" variant="h6" component="div" sx={{ color: Colors.TextElevated }}>
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </a>
        ))}
      </Carousel>
    </Box>
  );
}